import Separator from "../../UI/Separator/Separator";
import classes from "./LightCard.module.css";

const LightCard = (props) => {
  return (
    <div className={classes.lightCard} style={{ width: props.cardWidth }}>
      <p
        className={classes.lightCardTitle}
        style={{ color: props.titleColor, fontWeight: props.titleWeight }}
      >
        {props.texts.title}
      </p>

      <Separator height={props.separatorHeight} />
      <p
        className={classes.text1}
        style={{
          color: props.text1Color,
          fontWeight: props.text1Weight,
          width: props.text1Width,
        }}
      >
        {props.texts.text1}
      </p>
      <p
        className={classes.text2}
        style={{
          fontWeight: props.fontWeight,
          color: props.text2Color,
          margin: props.text2Margin,
          width: props.text2Width,
        }}
      >
        {props.texts.text2}
      </p>
      <p
        className={classes.text3}
        style={{ color: props.text3Color, width: props.text3Width }}
      >
        {props.texts.text3}
      </p>
    </div>
  );
};

export default LightCard;
