import { useState } from "react";

const useValidation = (validateValue) => {
  const [enteredValue, setEnteredValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const isValid = validateValue ? validateValue(enteredValue) : null;
  const wrongInput = isTouched && !isValid;

  const valueChangeHandler = (event) => {
    setEnteredValue(event.target.value);
  };

  const inputBlurHandler = (event) => {
    setIsTouched(true);
  };

  const reset = () => {
    setEnteredValue("");
    setIsTouched(false);
  };

  return {
    value: enteredValue,
    isValid: isValid,
    wrongInput: wrongInput,
    reset: reset,
    valueChangeHandler,
    inputBlurHandler,
  };
};

export default useValidation;
