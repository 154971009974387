import { Fragment } from "react";
import ContactForm from "../components/forms/ContactForm";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import SloganCard from "../components/cards/SloganCard/SloganCard";
import ColorBackgroundContainer from "../components/containers/ColorBackgroundContainer/ColorBackgroundContainer";
import FourCardsGroupV3 from "../components/groups/FourCardsGroupV3/FourCardsGroupV3";
import ContactCards1 from "../components/cards/ContactCards/ContactCards1";
import ContactCards2 from "../components/cards/ContactCards/ContactCards2";

import FinalFooter from "../components/UI/FinalFooter/FinalFooter";
import Colors from "../data/Colors";

import classes from "./pages.module.css";

const Contact = (props) => {
  return (
    <Fragment>
      <ParallaxProvider>
        <Parallax speed={-20}>
          <section id="section-one" className={classes.contact1}>
            <SloganCard slogan={props.contact} />
          </section>
        </Parallax>

        <section id="section-two" className={classes.contact2}>
          <ColorBackgroundContainer height="60rem" color={Colors.VINA_WHITE}>
            <div className={classes.container}>
              <FourCardsGroupV3
                fourCards={props.contact.fourCards}
              ></FourCardsGroupV3>
            </div>
          </ColorBackgroundContainer>
        </section>

        <section id="section-three" className={classes.contact3_1}>
          <div className={classes.container}>
            <ContactCards1
              doubleCards={props.contact.doubleCards}
            ></ContactCards1>
          </div>
        </section>
        <section id="section-three" className={classes.contact3_2}>
          <div className={classes.container}>
            <ContactCards2
              doubleCards={props.contact.doubleCards}
            ></ContactCards2>
          </div>
        </section>

        <section id="section-four" className={classes.contact4}>
          <div className={classes.container}>
            <ContactForm
              form={props.contact.form}
              subjectPrefix="Kontaktformulär: "
            />
          </div>
        </section>

        <section id="section-five" style={{ height: "13rem" }}>
          <FinalFooter texts={props.finalFooter} />
        </section>
      </ParallaxProvider>
    </Fragment>
  );
};

export default Contact;
