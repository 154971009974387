import classes from "./Separator.module.css";
const Separator = (props) => {
  return (
    <div
      className={classes.separator}
      style={{
        width: props.width,
        height: props.height,
        margin: props.margin,
      }}
    ></div>
  );
};

export default Separator;
