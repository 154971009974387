import { useDispatch } from "react-redux";
import { formActions } from "../../store/form-slice";
import { useSelector } from "react-redux";

import { Fragment } from "react";
import useValidation from "../../hooks/useValidation";
import classes from "./ContactForm.module.css";
import { send } from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = (props) => {
  const validCaptcha = useSelector((state) => state.form.isValidCaptcha);
  const captchaValue = useSelector((state) => state.form.captchaValue);
  const sentMessage = useSelector((state) => state.form.isMessageSent);

  const dispatch = useDispatch();

  const {
    value: enteredFirstname,
    isValid: isFirstnameValid,
    wrongInput: firstnameWrongInput,
    valueChangeHandler: firstnameChangeHandler,
    inputBlurHandler: firstnameBlurHandler,
    reset: resetFirstname,
  } = useValidation((value) => value.trim().length >= 2);

  const {
    value: enteredLastname,
    isValid: isLastnameValid,
    wrongInput: lastnameWrongInput,
    valueChangeHandler: lastnameChangeHandler,
    inputBlurHandler: lastnameBlurHandler,
    reset: resetLastname,
  } = useValidation((value) => value.trim().length >= 2);

  const {
    value: enteredSubject,
    isValid: isSubjectValid,
    wrongInput: subjectWrongInput,
    valueChangeHandler: subjectChangeHandler,
    inputBlurHandler: subjectBlurHandler,
    reset: resetSubject,
  } = useValidation((value) => value.trim().length >= 2);

  const {
    value: enteredMail,
    isValid: isMailValid,
    wrongInput: mailWrongInput,
    valueChangeHandler: mailChangeHandler,
    inputBlurHandler: mailBlurHandler,
    reset: resetMail,
  } = useValidation((value) => value.includes("@"));

  const {
    value: enteredPhone,
    valueChangeHandler: phoneChangeHandler,
    reset: resetPhone,
  } = useValidation();

  const {
    value: enteredMessage,
    valueChangeHandler: messageChangeHandler,
    reset: resetMessage,
  } = useValidation();

  const submitButtoniIsDisabled =
    !isFirstnameValid ||
    !isLastnameValid ||
    !isSubjectValid ||
    !isMailValid ||
    !validCaptcha;

  const submitFormHandler = (event) => {
    event.preventDefault();

    const message = {
      name: enteredFirstname,
      lastname: enteredLastname,
      mail: enteredMail,
      telephone: enteredPhone,
      subject: `${props.subjectPrefix}${enteredSubject}`,
      message: enteredMessage,
      "g-recaptcha-response": captchaValue,
    };

    resetForm();
    console.log(message);
    sendEmail(message);
  };

  const resetForm = () => {
    resetFirstname();
    resetLastname();
    resetSubject();
    resetMail();
    resetPhone();
    resetMessage();
  };

  const sendEmail = (data) => {
    const templateValues = {
      subject: data.subject,
      to_name: "Linda Åkerström",
      from_email: data.mail,
      from_name: data.name + " " + data.lastname,
      message: data.message,
      phone_number: data.telephone,
      "g-recaptcha-response": data["g-recaptcha-response"],
    };

    dispatch(formActions.setSentMessage());

    send(
      process.env.REACT_APP_MAIL_SERVICE,
      process.env.REACT_APP_MAIL_TEMPLATE,
      templateValues,
      process.env.REACT_APP_MAIL_PUBLIC
    );
  };

  const confirmDiv = (
    <div className={classes.confirmMsg}>{props.form.confirmMsg}</div>
  );

  const captchaCallback = (value) => {
    dispatch(formActions.captchaCallback(value));
  };

  const sendNewMessage = () => {
    dispatch(formActions.sendNewMessage());
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.title}>{props.form.title}</div>

        {sentMessage ? (
          <div>
            {confirmDiv}
            <button onClick={() => sendNewMessage()}>Skicka ett till</button>
          </div>
        ) : (
          <div>
            <p className={classes.undertext}>{props.form.undertext}</p>
            <form className={classes.form} onSubmit={submitFormHandler}>
              <div>
                <div className={classes.double}>
                  <div className={classes.errorBlock}>
                    <input
                      id="name"
                      type="text"
                      placeholder={props.form.placeholders.ph1}
                      className={firstnameWrongInput ? classes.error : ""}
                      style={{
                        width: "9.5rem",
                      }}
                      value={enteredFirstname}
                      onChange={firstnameChangeHandler}
                      onBlur={firstnameBlurHandler}
                    />
                    <div style={{ height: "0.8rem" }}>
                      {firstnameWrongInput && (
                        <div className={classes.errorMsg}>
                          {props.form.errorMessages.em1}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <input
                      id="lastname"
                      type="text"
                      placeholder={props.form.placeholders.ph2}
                      className={lastnameWrongInput ? classes.error : ""}
                      style={{
                        width: "9.5rem",
                      }}
                      value={enteredLastname}
                      onChange={lastnameChangeHandler}
                      onBlur={lastnameBlurHandler}
                    />
                    <div style={{ height: "0.8rem" }}>
                      {lastnameWrongInput && (
                        <div className={classes.errorMsg}>
                          {props.form.errorMessages.em2}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <input
                      id="subject"
                      type="text"
                      className={subjectWrongInput ? classes.error : ""}
                      placeholder={props.form.placeholders.ph3}
                      value={enteredSubject}
                      onChange={subjectChangeHandler}
                      onBlur={subjectBlurHandler}
                    />
                    <div className={classes.errorContainer}>
                      {subjectWrongInput && (
                        <div className={classes.errorMsg}>
                          {props.form.errorMessages.em3}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={classes.errorBlock}>
                    <input
                      id="email"
                      type="text"
                      className={mailWrongInput ? classes.error : ""}
                      placeholder={props.form.placeholders.ph4}
                      value={enteredMail}
                      onChange={mailChangeHandler}
                      onBlur={mailBlurHandler}
                    />
                    <div className={classes.errorContainer}>
                      {mailWrongInput && (
                        <div className={classes.errorMsg}>
                          {props.form.errorMessages.em4}
                        </div>
                      )}
                    </div>
                  </div>

                  <input
                    id="phone"
                    type="text"
                    placeholder={props.form.placeholders.ph5}
                    value={enteredPhone}
                    onChange={phoneChangeHandler}
                  />
                </div>
              </div>

              <div>
                <div>
                  <textarea
                    id="message"
                    placeholder={props.form.placeholders.ph6}
                    value={enteredMessage}
                    onChange={messageChangeHandler}
                  />
                </div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
                  onChange={captchaCallback}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "0.5rem",
                  }}
                ></ReCAPTCHA>
                <div className={classes.buttonContainer}>
                  <button type="submit" disabled={submitButtoniIsDisabled}>
                    {props.form.button}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ContactForm;
