import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui-slice";

import { HashLink } from "react-router-hash-link";
import Separator from "../../UI/Separator/Separator";
import classes from "./VacancyCard.module.css";

const VacancyCard = (props) => {
  const dispatch = useDispatch();

  const vacancyItemHandler = () => {
    dispatch(uiActions.setItemContent(props.item));
    dispatch(uiActions.expandVacancyItem());
    dispatch(uiActions.showBackdrop());
  };

  return (
    <div className={classes.vacancyCard}>
      <div className={classes.titleBlock}>
        <p className={classes.title}>{props.item.title}</p>
      </div>
      <Separator width="10rem" height="0.15rem" margin="0" />
      <div className={classes.textBlock}>
        <p className={classes.shortText}>{props.item.shortText}</p>
      </div>
      <HashLink
        smooth
        className={classes.links}
        to="/lediga#section-three"
        style={{
          textDecoration: "none",
        }}
      >
        <button onClick={vacancyItemHandler}>Läs mer</button>
      </HashLink>
    </div>
  );
};

export default VacancyCard;
