import React from "react";

import classes from "./CloseButton.module.css";

const CloseButton = (props) => (
  <div className={classes.closeButton} onClick={props.onClose}>
    x
  </div>
);

export default CloseButton;
