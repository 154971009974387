import classes from "./SloganCard.module.css";

const SloganCard = (props) => {
  return (
    <div
      className={classes.textContainer}
      style={{ top: props.top, left: props.left }}
    >
      <p className={classes.slogan}> {props.slogan.slogan} </p>
      <p className={classes.undertext}>{props.slogan.undertext}</p>
    </div>
  );
};

export default SloganCard;
