import { Fragment } from "react";
import { HashLink } from "react-router-hash-link";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import ColorBackgroundContainer from "../components/containers/ColorBackgroundContainer/ColorBackgroundContainer";
import ThreeCardsGroup from "../components/groups/ThreeCardsGroup/ThreeCardsGroup";
import SloganCard from "../components/cards/SloganCard/SloganCard";
import LightCard from "../components/cards/LightCard/LightCard";

import Colors from "../data/Colors";

import classes from "./pages.module.css";

const Home = (props) => {
  return (
    <Fragment>
      <ParallaxProvider>
        <Parallax speed={-20}>
          <section id="section-one" className={classes.home1}>
            <SloganCard slogan={props.home} />
          </section>
        </Parallax>

        <section id="section-two" className={classes.home2}>
          <ColorBackgroundContainer
            color={Colors.VINA_LIGHT_GRAY}
            height="38rem"
          >
            <div className={classes.container}>
              <HashLink
                smooth
                className={classes.links}
                to="/kontakt#section-four"
                style={{
                  textDecoration: "none",
                }}
              >
                <LightCard
                  cardWidth="24rem"
                  titleWeight="bold"
                  texts={props.home.firstCard}
                  text2Margin="1rem 0"
                  text1Width="auto"
                  text2Width="auto"
                  text3Width="auto"
                  separatorHeight="0.15rem"
                ></LightCard>
              </HashLink>
            </div>
          </ColorBackgroundContainer>
        </section>
        <section id="section-three" className={classes.home3}>
          <div className={classes.container}>
            <ThreeCardsGroup
              threeCards={props.home.threeCards}
              width="18rem"
              height="16rem"
            />
          </div>
        </section>

        <section id="section-four" className={classes.home4}></section>
      </ParallaxProvider>
    </Fragment>
  );
};

export default Home;
