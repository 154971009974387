import classes from "./Footer.module.css";
import Obfuscate from "react-obfuscate";

const Footer = (props) => {
  return (
    <footer className={classes.footer}>
      <div>
        <p>{props.footer.line1}</p>
        <p>{props.footer.line2}</p>
        <p>{props.footer.line3}</p>
        <p>
          <Obfuscate email={props.footer.line4}></Obfuscate>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
