import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui-slice";
import { useSelector } from "react-redux";

import { HashLink } from "react-router-hash-link";
import Separator from "../../UI/Separator/Separator";
import CloseButton from "../../UI/CloseButton/CloseButton";
import classes from "./VacancyCardDetails.module.css";

const VacancyCardDetails = (props) => {
  const itemContent = useSelector((state) => state.ui.itemContent);
  const dispatch = useDispatch();

  const closeItemHandler = () => {
    dispatch(uiActions.closeVacancyItem());
    dispatch(uiActions.hideBackdrop());
  };

  return (
    <div className={classes.container}>
      <CloseButton onClose={closeItemHandler} />
      <div className={classes.vacancyCardDetails}>
        <p className={classes.title}>{itemContent.title}</p>
        <Separator width="10rem" height="0.15rem" margin="0 auto" />
        <p className={classes.shortText}>{itemContent.shortText}</p>

        <ul>
          {itemContent.requirements.map((req) => (
            <li key={Math.random()}>{req}</li>
          ))}
        </ul>

        <p className={classes.text}>{itemContent.text1}</p>
        <p className={classes.text}>{itemContent.text2}</p>
        <p className={classes.text}>{itemContent.text3}</p>
        <HashLink
          smooth
          className={classes.links}
          to="/lediga#section-three"
          style={{
            textDecoration: "none",
          }}
        ></HashLink>
      </div>
    </div>
  );
};

export default VacancyCardDetails;
