import Obfuscate from "react-obfuscate";
import classes from "./FinalFooter.module.css";

const FinalFooter = (props) => {
  return (
    <footer className={classes.finalFooter}>
      <div className={classes.container}>
        <div>
          <div style={{ height: "2.5rem" }}>
            <img
              src="images/footPhone.png"
              alt="phone"
              style={{ height: "2.25rem" }}
            />
          </div>
          <div className={classes.text}>{props.texts.card1.text1}</div>
          <div className={classes.text}>
            <Obfuscate tel={props.texts.card1.text2}></Obfuscate>
          </div>
        </div>

        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.google.com/maps/place/Kungsgatan+10,+111+43+Stockholm/@59.336217,18.0686325,18.56z/data=!4m5!3m4!1s0x465f9d5ce3a638d1:0xc49e6ee3dae0d9dd!8m2!3d59.336241!4d18.0692618"
        >
          <div>
            <div style={{ height: "2.5rem" }}>
              <img
                src="images/footHere.png"
                alt="here"
                style={{ height: "2.5rem" }}
              />
            </div>
            <div className={classes.text} style={{ color: "black" }}>
              {props.texts.card2.text1}
            </div>
            <div>
              <p className={classes.text}>{props.texts.card2.text2}</p>
              <p className={classes.text}>{props.texts.card2.text3}</p>
              <p className={classes.text}>{props.texts.card2.text4}</p>
            </div>
          </div>
        </a>

        <div>
          <div style={{ height: "2.5rem" }}>
            <img
              src="images/footMail.png"
              alt="mail"
              style={{ height: "2rem" }}
            />
          </div>
          <div className={classes.text}>{props.texts.card3.text1}</div>
          <div className={classes.text}>
            <Obfuscate email={props.texts.card3.text2}></Obfuscate>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FinalFooter;
