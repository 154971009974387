import { Fragment } from "react";

import classes from "./ThreeCardsGroup.module.css";

import StandardCard from "../../cards/StandardCard/StandardCard";

const ThreeCardsGroup = (props) => {
  return (
    <Fragment>
      <p className={classes.title}> {props.threeCards.title}</p>
      <div className={classes.threeCardsGroup}>
        <StandardCard
          texts={props.threeCards.one}
          width={props.width}
          height={props.height}
          titleFontSize={props.titleFontSize}
          textFontSize={props.textFontSize}
        />
        <StandardCard
          texts={props.threeCards.two}
          width={props.width}
          height={props.height}
          titleFontSize={props.titleFontSize}
          textFontSize={props.textFontSize}
        />
        <StandardCard
          texts={props.threeCards.three}
          width={props.width}
          height={props.height}
          titleFontSize={props.titleFontSize}
          textFontSize={props.textFontSize}
        />
      </div>
    </Fragment>
  );
};

export default ThreeCardsGroup;
