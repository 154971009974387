import { Fragment } from "react";
import Obfuscate from "react-obfuscate";

import classes from "./ContactCards.module.css";

const ContactCards1 = (props) => {
  return (
    <Fragment>
      <div className={classes.doubleCardsGroupContainer}>
        <div className={classes.card1}>
          <div className={classes.title}>{props.doubleCards.two.title}</div>
          <div className={classes.text}>{props.doubleCards.two.text}</div>
          <p className={classes.undertext}>
            {props.doubleCards.two.undertext1}
          </p>
          <p className={classes.undertext}>
            {props.doubleCards.two.undertext2}
          </p>

          <div className={classes.contact1}>
            <div className={classes.contactBox}>
              <img src="/images/phone1-grey.png" alt="phone" />
              <p>
                <Obfuscate tel={props.doubleCards.two.contactText1}></Obfuscate>
              </p>
            </div>

            <div className={classes.contactBox}>
              <img src="/images/mail1-grey.png" alt="phone" />
              <p>
                <Obfuscate
                  email={props.doubleCards.two.contactText2}
                ></Obfuscate>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactCards1;
