import classes from "./StandardCard.module.css";
import Separator from "../../UI/Separator/Separator";

const StandardCard = (props) => {
  return (
    <div
      className={classes.standardCard}
      style={{ width: props.width, height: props.height, margin: props.margin }}
    >
      <div style={{ width: props.width, height: props.height }}>
        <p className={classes.title} style={{ fontSize: props.titleFontSize }}>
          {props.texts.title}
        </p>

        <Separator />
        <div
          style={{
            width: "90%",
            margin: "0 auto",
          }}
        >
          <p className={classes.text} style={{ fontSize: props.textFontSize }}>
            {props.texts.text1}
          </p>
          <p className={classes.text} style={{ fontSize: props.textFontSize }}>
            {props.texts.text2}
          </p>
          <p className={classes.text} style={{ fontSize: props.textFontSize }}>
            {props.texts.text3}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StandardCard;
