import React, { Fragment } from "react";
import classes from "./Sidedrawer.module.css";
import Navigator from "../Navigator/Navigator";

const sideDrawer = (props) => {
  return (
    <Fragment>
      <div className={classes.sidedrawer}>
        <Navigator header={props.header}></Navigator>
      </div>
    </Fragment>
  );
};

export default sideDrawer;
