import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    isPageScrolled: false,
    isSideDrawerVisible: false,
    isVacancyItemExpanded: false,
    itemContent: "",
    isBackdropVisible: false,
  },
  reducers: {
    setIsPageScrolled(state) {
      const scrollValue = document.documentElement.scrollTop;
      if (scrollValue > 200) {
        state.isPageScrolled = true;
      } else {
        state.isPageScrolled = false;
      }
    },

    toggleSidedrawer(state) {
      state.isSideDrawerVisible = !state.isSideDrawerVisible;
    },
    closeSidedrawer(state) {
      state.isSideDrawerVisible = false;
    },

    expandVacancyItem(state) {
      state.isVacancyItemExpanded = true;
    },

    closeVacancyItem(state) {
      state.isVacancyItemExpanded = false;
    },

    setItemContent(state, action) {
      state.itemContent = action.payload;
    },

    showBackdrop(state) {
      state.isBackdropVisible = true;
      document.body.style.overflow = "hidden";
    },

    hideBackdrop(state) {
      state.isBackdropVisible = false;
      document.body.style.overflow = "visible";
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
