import { Fragment } from "react";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { HashLink } from "react-router-hash-link";

import { useSelector } from "react-redux";

import VacanciesGroup from "../components/groups/VacanciesGroup/VacanciesGroup";
import SloganCard from "../components/cards/SloganCard/SloganCard";
import ColorBackgroundContainer from "../components/containers/ColorBackgroundContainer/ColorBackgroundContainer";
import StandardCard from "../components/cards/StandardCard/StandardCard";
import LightCard from "../components/cards/LightCard/LightCard";
import ContactForm from "../components/forms/ContactForm";
import Separator from "../components/UI/Separator/Separator";
import Colors from "../data/Colors.js";

import classes from "./pages.module.css";
import Backdrop from "../components/UI/Backdrop/Backdrop";

const Vacancy = (props) => {
  const isBackdropVisible = useSelector((state) => state.ui.isBackdropVisible);

  return (
    <Fragment>
      {isBackdropVisible && <Backdrop />}
      <ParallaxProvider>
        <Parallax speed={-20}>
          <section id="section-one" className={classes.vacancy1}>
            <SloganCard slogan={props.vacancy} />
          </section>
        </Parallax>

        <section id="section-two" className={classes.vacancy2}>
          <ColorBackgroundContainer color={Colors.VINA_WHITE} height="60rem">
            <div className={classes.container}>
              <HashLink
                smooth
                to="/lediga#section-three"
                style={{ textDecoration: "none" }}
              >
                <LightCard
                  text1Weight="bold"
                  texts={props.vacancy.card1}
                  text2Margin="1rem 0 1rem 0"
                  separatorHeight="0"
                />
                <Separator width="25rem" height="0.15rem" />
              </HashLink>
            </div>
          </ColorBackgroundContainer>
        </section>

        <section id="section-three" className={classes.vacancy3}>
          <div>
            <VacanciesGroup vacancy={props.vacancy} />
          </div>
        </section>

        <section id="section-three_dot_one" className={classes.vacancy31}>
          <div className={classes.container}>
            <HashLink
              smooth
              to="/lediga#section-four"
              style={{ textDecoration: "none" }}
            >
              <StandardCard
                texts={props.vacancy.card2}
                width="25rem"
                height="13rem"
              />
            </HashLink>
          </div>
        </section>

        <section id="section-four" className={classes.vacancy4}>
          <div className={classes.container}>
            <ContactForm form={props.vacancy.form} subjectPrefix="Ansökan: " />
          </div>
        </section>
      </ParallaxProvider>
    </Fragment>
  );
};

export default Vacancy;
