import classes from "./ColorBackgroundContainer.module.css";

const ColorBackgroundContainer = (props) => {
  return (
    <div
      className={classes.colorBackgroundContainer}
      style={{
        height: props.height,
        backgroundColor: props.color,
      }}
    >
      {props.children}
    </div>
  );
};

export default ColorBackgroundContainer;
