import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui-slice";
import { HashLink, NavHashLink } from "react-router-hash-link";

import classes from "./MainHeader.module.css";

import DrawerToggle from "../DrawerToggle/DrawerToggle";

const MainHeader = (props) => {
  const isScrolled = useSelector((state) => state.ui.isPageScrolled);
  const dispatch = useDispatch();

  const closeSidedrawer = () => {
    dispatch(uiActions.closeSidedrawer());
  };

  const changeHeaderOnScroll = () => {
    dispatch(uiActions.setIsPageScrolled());
  };

  window.addEventListener("scroll", changeHeaderOnScroll);

  const headerClass = !isScrolled
    ? classes.header
    : `${classes.header} ${classes.small}`;

  return (
    <header className={headerClass}>
      <HashLink
        smooth
        to="/hem#top"
        onClick={closeSidedrawer}
        style={{ border: "0" }}
      >
        <img
          className={classes.image}
          src={process.env.PUBLIC_URL + "images/logga_black.png"}
          alt="logo"
        />
      </HashLink>
      <nav>
        <ul>
          <li>
            <NavHashLink
              activeClassName={classes.active}
              smooth
              to="/om-oss#top"
            >
              {props.header.nav1}
            </NavHashLink>
          </li>
          <li>
            <NavHashLink
              activeClassName={classes.active}
              smooth
              to="/lediga#top"
            >
              {props.header.nav2}
            </NavHashLink>
          </li>
          <li>
            <NavHashLink
              activeClassName={classes.active}
              smooth
              to="/kontakt#top"
            >
              {props.header.nav3}
            </NavHashLink>
          </li>
        </ul>
      </nav>
      <DrawerToggle />
    </header>
  );
};

export default MainHeader;
