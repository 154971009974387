import { Fragment } from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import SloganCard from "../components/cards/SloganCard/SloganCard";
import ColorBackgroundContainer from "../components/containers/ColorBackgroundContainer/ColorBackgroundContainer";
import Colors from "../data/Colors";
import LightCard from "../components/cards/LightCard/LightCard";
import Separator from "../components/UI/Separator/Separator";

import classes from "./pages.module.css";

const AboutUs = (props) => {
  return (
    <Fragment>
      <ParallaxProvider>
        <Parallax speed={-20}>
          <section id="aboutUs1-image" className={classes.aboutUs1}>
            <SloganCard slogan={props.about_us} />
          </section>
        </Parallax>

        <section id="aboutUs2-card1" className={classes.aboutUs2}>
          <ColorBackgroundContainer color={Colors.VINA_WHITE} height="39rem">
            <div className={classes.container}>
              <img src="images/people.png" alt="people" />
              <LightCard texts={props.about_us.card3} separatorHeight="0" />

              <Separator width="22rem" height="0.15rem" />
            </div>
          </ColorBackgroundContainer>
        </section>

        <section id="aboutUs3-card2" className={classes.aboutUs3}>
          <div className={classes.container}>
            <img
              src="images/circleArrows.png"
              alt="circle_arrows"
              style={{ height: "7rem" }}
            />

            <LightCard
              texts={props.about_us.card2}
              text2Margin="1.5rem 0rem"
              fontWeight="bold"
              separatorHeight="0"
            />
            <Separator width="22rem" height="0.15rem" />
          </div>
        </section>

        <section id="aboutUs4-card3" className={classes.aboutUs4}>
          <div className={classes.container}>
            <img
              src="images/here.png"
              alt="here_icon"
              style={{ height: "4rem" }}
            />
            <LightCard
              texts={props.about_us.card1}
              titleColor={Colors.VINA_GOLD}
              separatorHeight="0"
            />
          </div>
        </section>
      </ParallaxProvider>
    </Fragment>
  );
};

export default AboutUs;
