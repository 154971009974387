import React from "react";

import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui-slice";
import classes from "./DrawerToggle.module.css";

const DrawerToggle = (props) => {
  const dispatch = useDispatch();

  const toggleHandler = () => {
    dispatch(uiActions.toggleSidedrawer());
  };

  return (
    <div className={classes.drawerToggle} onClick={toggleHandler}>
      <div />
      <div />
      <div />
    </div>
  );
};

export default DrawerToggle;
