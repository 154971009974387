import { createSlice } from "@reduxjs/toolkit";

const formSlice = createSlice({
  name: "form",
  initialState: {
    isValidCaptcha: false,
    captchaValue: "",
    isMessageSent: false,
  },
  reducers: {
    captchaCallback(state, action) {
      state.isValidCaptcha = true;
      state.captchaValue = action.payload;
    },

    sendNewMessage(state) {
      state.isMessageSent = false;
      state.isValidCaptcha = false;
    },

    setSentMessage(state) {
      state.isMessageSent = true;
    },
  },
});

export const formActions = formSlice.actions;

export default formSlice;
