import { Fragment } from "react";

import { useSelector } from "react-redux";

import VacancyCard from "../../cards/VacancyCard/VacancyCard";
import VacancyCardDetails from "../../cards/VacancyCardDetails/VacancyCardDetails";
import classes from "./VacanciesGroup.module.css";

let item = {};

const VacanciesGroup = (props) => {
  const isItemExpanded = useSelector((state) => state.ui.isVacancyItemExpanded);

  const selectedItem = <VacancyCardDetails item={item} />;

  return (
    <Fragment>
      <div className={classes.vacancyGroupContainer}>
        {isItemExpanded ? (
          selectedItem
        ) : (
          <div className={classes.vacancyGroupItems}>
            {props.vacancy.vacancies.map((item) => (
              <VacancyCard key={item.id} item={item} />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default VacanciesGroup;
