const Colors = {
  VINA_WHITE: "#ffffff",
  VINA_BLACK: "#000000",
  VINA_GOLD: "#f4cd00",
  VINA_LIGHT_GOLD: "#fffaca",
  VINA_PETROL: "#01503f",
  VINA_LIGHT_GRAY: "#e9e7e3",
};

export default Colors;
