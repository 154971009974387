import { Fragment } from "react";
import Obfuscate from "react-obfuscate";
import Separator from "../../UI/Separator/Separator";

import classes from "./ContactCards.module.css";

const ContactCards2 = (props) => {
  return (
    <Fragment>
      <div className={classes.contactCardsContainer}>
        <div className={classes.card2}>
          <div className={classes.imageContainer}>
            <img src="/images/linda_01.png" alt="Linda" />
          </div>
          <div className={classes.text}>{props.doubleCards.one.text}</div>

          <Separator />

          <div className={classes.contact2}>
            <p>{props.doubleCards.one.contactText1}</p>

            <p>
              <Obfuscate email={props.doubleCards.one.contactText2}></Obfuscate>
            </p>
            <p>
              <Obfuscate tel={props.doubleCards.one.contactText3}></Obfuscate>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactCards2;
