import { NavHashLink } from "react-router-hash-link";
import classes from "./Navigator.module.css";
import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui-slice";

const Navigator = (props) => {
  const dispatch = useDispatch();

  const closeSidedrawer = () => {
    dispatch(uiActions.closeSidedrawer());
  };

  return (
    <nav className={classes.navigator}>
      <p>
        <NavHashLink
          activeClassName={classes.active}
          smooth
          to="/om-oss#top"
          onClick={closeSidedrawer}
        >
          {props.header.nav1}
        </NavHashLink>
      </p>

      <p>
        <NavHashLink
          activeClassName={classes.active}
          smooth
          to="/lediga#top"
          onClick={closeSidedrawer}
        >
          {props.header.nav2}
        </NavHashLink>
      </p>

      <p>
        <NavHashLink
          activeClassName={classes.active}
          smooth
          to="/kontakt#top"
          onClick={closeSidedrawer}
        >
          {props.header.nav3}
        </NavHashLink>
      </p>
    </nav>
  );
};

export default Navigator;
