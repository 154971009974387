import { Fragment } from "react";

import classes from "./FourCardsGroupV3.module.css";

import StandardCard from "../../cards/StandardCard/StandardCard";

const FourCardsGroupV3 = (props) => {
  return (
    <Fragment>
      <div className={classes.fourCardsGroupContainer}>
        <div className={classes.itemsContainer}>
          <div className={classes.row}>
            <StandardCard
              texts={props.fourCards.one}
              width="17rem"
              height="16rem"
              margin="1rem 2rem"
            />
            <StandardCard
              texts={props.fourCards.two}
              width="17rem"
              height="16rem"
              margin="1rem 2rem"
            />
          </div>

          <div className={classes.row}>
            <StandardCard
              texts={props.fourCards.three}
              width="17rem"
              height="17rem"
              margin="1rem 2rem"
            />
            <StandardCard
              texts={props.fourCards.four}
              width="17rem"
              height="17rem"
              margin="1rem 2rem"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FourCardsGroupV3;
