import { Route, Redirect, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uiActions } from "./store/ui-slice";

import MainHeader from "./components/UI/MainHeader/MainHeader";
import SideDrawer from "./components/UI/Sidedrawer/Sidedrawer";
import Footer from "./components/UI/Footer/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Vacancy from "./pages/Vacancy";
import Contact from "./pages/Contact";

import "./App.css";
import { useEffect, useState } from "react";

function App() {
  const dispatch = useDispatch();

  const [texts, setTexts] = useState();

  useEffect(() => {
    fetch("data/Texts.json")
      .then((res) => res.json())
      .then((data) => setTexts(data));
  }, []);

  const isSideDrawerVisible = useSelector(
    (state) => state.ui.isSideDrawerVisible
  );

  const { pathname } = useLocation();

  return (
    texts && (
      <div className="App">
        <MainHeader header={texts.header} />
        {isSideDrawerVisible && <SideDrawer header={texts.header} />}
        <main
          onClick={() => {
            dispatch(uiActions.closeSidedrawer());
          }}
        >
          <Route path="/" exact>
            <Redirect to="/hem" />
          </Route>
          <Route path="/hem">
            <Home home={texts.home} />
          </Route>
          <Route path="/om-oss">
            <AboutUs about_us={texts.about_us} />
          </Route>
          <Route path="/lediga">
            <Vacancy vacancy={texts.vacancy} />
          </Route>
          <Route path="/kontakt">
            <Contact contact={texts.contact} finalFooter={texts.finalFooter} />
          </Route>
          <Route path="/*">
            <Redirect to="/hem" />
          </Route>
          <footer>
            {pathname !== "/kontakt" && <Footer footer={texts.footer} />}
          </footer>
        </main>
      </div>
    )
  );
}

export default App;
